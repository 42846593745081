import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navbar } from '../../layout/navbar'
import ClientListingClosure from '../clients'

function Closure() {
  return (
    <div className='bg-secondary'>
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="*" element={ <ClientListingClosure/> } />
      </Routes>
      </BrowserRouter>

    </div>
  )
}

export default Closure