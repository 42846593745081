import React, { useState } from 'react'
import { GrEdit } from "react-icons/gr";
import axios from 'axios';
import Loader from '../../components/loader';
import { ToastContainer, toast } from "react-toastify";

const Other = ({ data }) => {
  const [edit, setEdit] = useState(false)
  const [loading,setLoading]=useState(false)
  const [formData, setFormData] = useState({
    education: "",
    occupation: "",
    spouseName: "",
    maritalStatus: ""
  })
  const [error, setError] = useState(null);
  const annualSalary = [
    { key: "1", value: "Below 1 Lac" },
    { key: "2", value: "1-5 Lac" },
    { key: "3", value: "5-10 Lac" },
    { key: "4", value: "10-25 Lac" },
    { key: "5", value: "0.25-1 Cr" },
    { key: "6", value: ">1 Cr" },
  ];

  // Assuming similar structure for net worth
  const netWorthRanges = [
    { key: "1", value: "Below 1 Lac" },
    { key: "2", value: "1-5 Lac" },
    { key: "3", value: "5-10 Lac" },
    { key: "4", value: "10-25 Lac" },
    { key: "5", value: "0.25-1 Cr" },
    { key: "6", value: ">1 Cr" },
  ];

  const annualSalaryValue = data?.userInformation?.annualSalary;
  const netWorthValue = data?.userInformation?.netWorth;

  let displayedAnnualSalary = annualSalary.find(item => item.key === annualSalaryValue)?.value || "";
  let displayedNetWorth = netWorthRanges.find(item => item.key === netWorthValue)?.value || "";

  let SegmetValue = "";
  if (data?.userInformation?.nseCash) {
    SegmetValue = "NSE-Cash"
  }
  if (data?.userInformation?.nseFutureAndOption) {
    SegmetValue = SegmetValue + ", " + "NSE-F&O"
  }
  if (data?.userInformation?.bseCash) {
    SegmetValue = SegmetValue + ", " + "BSE-Cash"
  }
  if (data?.userInformation?.bseFutureAndOption) {
    SegmetValue = SegmetValue + ", " + "BSE-F&O"
  }
  if (data?.userInformation?.bseCds) {
    SegmetValue = SegmetValue + ", " + "BSE-CDS"
  }
  const handleEdit = () => {
    if (edit) {
      // If edit mode is already active, reset form data and close edit mode
      setFormData({
        education: "",
        occupation: "",
        spouseName: "",
        maritalStatus: ""
      });
      setEdit(false);
      setError("")
    } else {
      // If edit mode is not active, enable edit mode
      setEdit(true);
    }
  };

  const handleSubmit = () => {
    setLoading(true)
    const phoneNo = data.userDetail.phoneNumber;
    const storedToken = sessionStorage.getItem('authToken');
    const adminName = sessionStorage.getItem('admin');
    const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/user-data/edit-basicDetails/${phoneNo}?admin=${adminName}`;
    const headers = {
      'Authorization': storedToken,
    };
    axios.patch(apiUrl, formData, { headers })
      .then(response => {
        console.log('Data submitted successfully:', response.data);
        toast.success("Data submitted successfully")
        setEdit(false);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error submitting data:', error);
        toast.error("Error submitting data")
        setLoading(false)
      });
  };

  return (
    <>
      <Loader open={ loading} />
      {edit && (
        <div
          className={`w-full h-screen border border-2 border-dark `}
          style={{
            position: "fixed",
            cursor: "pointer",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "black",
            backgroundColor: "navy",
            padding: "1rem",
            borderRadius: "0.5rem",
            width: "30rem",
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
        >
          <span className='fw-semibold text-white fs-4'><u>Edit your Other details</u></span>
          <span className='bg-warning float-end text-black p fw-bold rounded-pill'
            style={{ width: "25px", fontSize: "20px", color: "white" }}
            onClick={handleEdit}
          >
            X
          </span>
          <div className='d-flex flex-column justify-content-center text-start ps-2 text-white '>
            <label htmlFor="education" className='fw-semibold'>Education</label>
            <input
              type="text"
              className="form-control"
              placeholder='Enter here...'
              id="education"
              onChange={(e) => setFormData({ ...formData, education: e.target.value })}
              value={formData.education}
            />
            <label htmlFor="occupation" className='fw-semibold'>Occupation</label>
            <input
              type="text"
              className="form-control"
              placeholder='Enter here...'
              id="occupation"
              onChange={(e) => setFormData({ ...formData, occupation: e.target.value })}
              value={formData.occupation}
            />
            {error && (<><span className='text-start'>{error}</span></>)}
            <label htmlFor="spouseName" className='fw-semibold'>Spouse Name</label>
            <input
              type="text"
              className="form-control"
              placeholder='Enter here...'
              id="spouseName"
              onChange={(e) => setFormData({ ...formData, spouseName: e.target.value })}
              value={formData.spouseName}
            />
            <label htmlFor="maritalStatus" className='fw-semibold'>Marital Status</label>
            <input
              type="text"
              className="form-control"
              id="maritalStatus"
              placeholder='Enter here...'
              onChange={(e) => setFormData({ ...formData, maritalStatus: e.target.value })}
              value={formData.maritalStatus}
            />
          </div>
          <button onClick={handleSubmit} className="p-1 rounded bg-primary text-white mt-4 fs-5">Submit</button>
        </div>
      )}
      <div className="head-text d-flex flex-row justify-content-between align-items-center p-1">
        <p className="text-start fw-bold fs-5">Other Details</p>
        <p className="d-flex align-items-center gap-2 border border-2" style={{ cursor: "pointer" }}
          onClick={handleEdit}
        >
          <GrEdit /> <span className='fw-bold fs-5'>Edit</span>
        </p>

      </div>
      <div className="Basic-details fw-semibold">
        <div className="text-start">
          <label for="floatingInputGroup1">Education</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.education} />
          <label for="floatingInputGroup1">Occupation</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.occupation} />
          {/* <label for="floatingInputGroup1">Annual Income</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={displayedValue} /> */}

          <label for="floatingInputGroup1">Spouse Name</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.spouseName} />

          <label for="floatingInputGroup1">Trading Experience</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.tradingExperience} />
          <label for="floatingInputGroup1">Client Code</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userDetail?.clientCode} />

          <label for="floatingInputGroup1">Occupation</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.occupation} />
          <label for="floatingInputGroup1">Nominee Check</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.nomineeCheck} />
          <label for="floatingInputGroup1">Tax Residence</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.taxResidence} />
          <label htmlFor="annualSalary">Annual Income</label>
          <input
            type="text"
            className="capitalize form-control"
            disabled
            id="annualSalary"
            value={displayedAnnualSalary}
          />
          <label htmlFor="netWorth">Net Worth</label>
          <input
            type="text"
            className="capitalize form-control"
            disabled
            id="netWorth"
            value={displayedNetWorth} // Display the net worth in a formatted way
          />
          <label for="floatingInputGroup1">Politically Exposed</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.politicallyExposed} />
          <label for="floatingInputGroup1">Segment</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={SegmetValue} />
          <label for="floatingInputGroup1">Equity Plan</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.brokerageAmount
          } />
          <label for="floatingInputGroup1">Nationality</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.nationality} />
          <label for="floatingInputGroup1">Action Taken By Sebi Or Any Other Authority</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.actionTakenBySebiOrAnyOtherAuthority
          } />
          <label for="floatingInputGroup1">Action Taken By Sebi Or Any Other Authority Description</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.actionTakenBySebiOrAnyOtherAuthorityDescription} />
        </div>
      </div>
    </>
  )
}

export default Other
