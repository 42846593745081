import React, { useState } from "react";
import "./styles.css";
import Slider from "../slider";
import { Stepper } from "../../components/stepper";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import {
  ADMIN_BASE_URL,
  ADMIN_BACKOFFICE_BASE_URL,
} from "../../constant/constants";
import Button from "react-bootstrap/Button";

const Verification = () => {
  const { loading, error, postData, fetchData } = useApi();
  const [loader, setLoader] = useState(false);
  const { clientId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [clientData, setClientData] = useState();

  async function getClientDetails() {
    const data = await fetchData(
      `rekyc/users/single/user/${clientId}`,
      {},
      ADMIN_BASE_URL
    );
    setClientData(data);
  }

  React.useEffect(() => {
    getClientDetails().catch((err) => console.error(err));
  }, []);

  // const handleApiCalls = async (url) => {
  //   try {
  //     const data = await fetchData(url, {}, ADMIN_BASE_URL);
  //     toast.success("E-sign initiated successfully");
  //   } catch (e) {
  //     toast.error(e.message ?? "Something went wrong");
  //   }
  // };
  const handleApiCalls = async () => {
    try {
      setLoader(true)
      // Replace 'your_xuserid_value' with the actual xuserid value
      const user = clientData.backOfficeDetails.userId;
      const token = sessionStorage.getItem("authToken")

      const response = await fetch('https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/esign/send/notification', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'xuserid': user,
          'Authorization': token
        },
        // You can add other options here, such as body for a POST request
      });

      if (response.ok) {
        setShowModal(false);
        console.log('API call successful');
        toast.success("E-sign Successful")
        // Handle the response data if needed
      } else {
        console.error('API call failed');
        toast.error('E-sign Unsuccessful');
        // Handle errors
      }
    } catch (error) {
      console.error('Error:', error);
    } finally { 
      setLoader(false)
    }
  };

  const toggle = () =>{
    setShowModal(true);
  }
  const toggleNot = () =>{
    setShowModal(false);
  }
  return (
    <main className="bg-secondary">
      <Loader open={loading} />
      <Loader open={loader} />
      <ToastContainer />
      <div className="verification-wrapper d-flex bg-light">
        <div className="bank-leftSide px-2 py-1 ">
          <Stepper clientData={clientData} />
        </div>
        <div className="bank-rightSide flex flex-col ">
          <Slider clientData={clientData} />
          <div className="flex items-end justify-end mt-2">

            <Button
              // className={`${searchData.filter === "ESIGNED" ? "d-block p-3" : "d-none"}`}
              className={
                clientData &&
                (clientData.currentStage === "REJECTED" ||
                  clientData.currentStage === "ESIGNED")
                  ? "d-none"
                  : ""
              }
              onClick={toggle}>Final Approve</Button>

          <div className="">
              {showModal && (
                <>
                  <div className="modal-wrapper "></div>
                  <div className="modall-container bg-white p-10 rounded-xl">
                    <p className="fs-5 fw-bold px-3 text-start" onClick={toggleNot}>X</p>
                    <div className="d-flex justify-content-center align-items-center h-75">
                    <Button
                      onClick={() =>
                        handleApiCalls(
                          `ekyc/user/leegality/notification/${clientId}`
                        )
                      }
                      className="self-end m-auto"
                      variant="outline-info"
                      size="lg"
                    >
                      E-sign
                    </Button>
                    </div>
                    
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Verification;

/**
 * 

    BANK_PROOF("BANK_PROOF"),
    FINANCIAL_PROOF("FINANCIAL_PROOF"),

 */
