import "./App.css";
import UserLogin from "./userLogin";
import MainHome from "./userAdmin"

function App() {
 
  return (
    <div className="App">
      <UserLogin />
      
     {/* <MainHome/> */}
    </div>
  );
}

export default App;
