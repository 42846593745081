import React from 'react'

const Other = ({ data }) => {
  let SegmetValue="";
  // if(data?.backOfficeDetails?.segmentLis){
  //   SegmetValue="Cash"
  // }
  // if(data?.backOfficeDetails?.segmentLis){
  //   SegmetValue=SegmetValue+", "+"CDS"
  // }
  // if(data?.userInformation?.nseFutureAndOption){
  //   SegmetValue=SegmetValue+", "+"F&O"
  // }
  // if(data?.userInformation?.nseMcx){
  //   SegmetValue=SegmetValue+", "+"MCX"
  // }

  const annualSalary = [
    { key: "1", value: "Below 1 Lac" },
    { key: "2", value: "1-5 Lac" },
    { key: "3", value: "5-10 Lac" },
    { key: "4", value: "10-25 Lac" },
    { key: "5", value: "0.25-1 Cr" },
    { key: "6", value: ">1 Cr" },
  ];

  const AnnualSalary = data?.rekycUserRequestedDetails?.incomeRange
  const ShowAnnualSalary = annualSalary.find((item) => item.key === AnnualSalary)?.value || "";
  console.log(ShowAnnualSalary,AnnualSalary);
  const Segment = data.backOfficeDetails.segmentList    
  const NewSegment = data.rekycUserRequestedDetails.newSegmentSelection

  const UpdatedSegment = Segment?.filter(segment => !NewSegment?.includes(segment))?.concat(
    NewSegment?.filter(segment => !Segment?.includes(segment))
  );

  return (
    <>
      <div className="head-text">
        <p className="text-start fw-bold fs-5">Other Details</p>
      </div>
      <div className="Basic-details fw-semibold ">
        <div className="text-start">
          <label for="floatingInputGroup1">Segment</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={data?.backOfficeDetails?.segmentList}  />
          <label for="floatingInputGroup1">Updated Segment</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={UpdatedSegment === undefined ? "No New Segment":UpdatedSegment}  />
          <label for="floatingInputGroup1">Annual Income as per Date</label>
          <input type="text" className="capitalize form-control" disabled id="floatingInputGroup1" value={ShowAnnualSalary} />
          {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Other