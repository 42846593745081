import React from "react";
import "./styles.css";
const Nominee = ({ data }) => {
  const { nominee } = data;

  return (
    <>
      <div className="nominee-wrapper">
        <div className="head-text flex-col">
          <p className="text-start fw-bold fs-5">Nominee's Details</p>
          <p className="fs-5 fw-bold text-start">Nominee 1</p>

          {data?.nominee && data?.nominee.length > 0 ? (
            <div className="text-start fw-semibold">
              <label for="floatingInputGroup1">Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.name
                }
              />
              <label for="floatingInputGroup1">Email</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.email
                }
              />
              <label for="floatingInputGroup1">Mobile Number</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.contactNumber
                }
              />
              <label for="floatingInputGroup1">Date Of Birth</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.dateOfBirth
                }
              />
              <label for="floatingInputGroup1">Guardian Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.name !== null
                    ? data.nominee[0]?.guardian?.name
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian Address</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.address !== null
                    ? data.nominee[0]?.guardian?.address
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian IdProof</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.idProof !== null
                    ? data.nominee[0]?.guardian?.idProof
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian IdProof No.</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.idProofNo !== null
                    ? data.nominee[0]?.guardian?.idProofNo
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">
                Guardian Relation With Nominee
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.relationWithNominee !== null
                    ? data.nominee[0]?.guardian?.relationWithNominee
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1"> Type of Document</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.typeOfDocument
                }
              />
              <label for="floatingInputGroup1">POI Reference Number</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.poiReferenceNumber
                }
              />
              <label for="floatingInputGroup1">Relation</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.relation
                }
              />
              <label for="floatingInputGroup1">Address 1</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.addressDto?.houseNumber
                }
              />
              <label for="floatingInputGroup1">Street</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.addressDto?.street
                }
              />
              <label for="floatingInputGroup1">City</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.addressDto?.district
                }
              />
              <label for="floatingInputGroup1">PinCode</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.addressDto?.pincode
                }
              />
              <label for="floatingInputGroup1">State</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.addressDto?.state
                }
              />
              <label for="floatingInputGroup1">Country</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.addressDto?.country
                }
              />
              <label for="floatingInputGroup1">Nominee Percentage</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.sharePercentage
                }
              />
            </div>
          ) : (
            <div className="text-start fw-bold fs-5">Nominee 1 not added</div>
          )}
          {data?.nominee && data?.nominee.length > 1 ? (
            <div className="text-start fw-semibold ">
              <p className="text-start fw-bold fs-5">Nominee 2</p>

              <label for="floatingInputGroup1">Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.name
                }
              />
              <label for="floatingInputGroup1">Email</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.email
                }
              />
              <label for="floatingInputGroup1">Mobile Number</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.contactNumber
                }
              />
              <label for="floatingInputGroup1">Date Of Birth</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.dateOfBirth
                }
              />
              <label for="floatingInputGroup1">Guardian Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.name !== null
                    ? data.nominee[0]?.guardian?.name
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian Address</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.address !== null
                    ? data.nominee[0]?.guardian?.address
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian IdProof</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.idProof !== null
                    ? data.nominee[0]?.guardian?.idProof
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian IdProof No.</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.idProofNo !== null
                    ? data.nominee[0]?.guardian?.idProofNo
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">
                Guardian Relation With Nominee
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.relationWithNominee !== null
                    ? data.nominee[0]?.guardian?.relationWithNominee
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1"> Type of Document</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[1]?.typeOfDocument
                }
              />
              <label for="floatingInputGroup1">POI Reference Number</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.poiReferenceNumber
                }
              />
              <label for="floatingInputGroup1">Relation</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.relation
                }
              />
              <label for="floatingInputGroup1">Address 1</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.addressDto?.houseNumber
                }
              />
              <label for="floatingInputGroup1">Street</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.addressDto?.street
                }
              />
              <label for="floatingInputGroup1">City</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.addressDto?.district
                }
              />
              <label for="floatingInputGroup1">PinCode</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.addressDto?.pincode
                }
              />
              <label for="floatingInputGroup1">State</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.addressDto?.state
                }
              />
              <label for="floatingInputGroup1">Country</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.addressDto?.country
                }
              />
              <label for="floatingInputGroup1">Nominee Percentage</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 1 &&
                  data.nominee[1]?.sharePercentage
                }
              />
            </div>
          ) : (
            <div className="text-start fw-bold fs-5">Nominee 2 not added</div>
          )}
          {data?.nominee && data?.nominee.length > 2 ? (
            <div className="text-start fw-semibold">
              <p className="text-start fw-bold fs-5">Nominee 3</p>

              <label for="floatingInputGroup1">Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.name
                }
              />
              <label for="floatingInputGroup1">Email</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.email
                }
              />
              <label for="floatingInputGroup1">Mobile Number</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.contactNumber
                }
              />
              <label for="floatingInputGroup1">Date Of Birth</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.dateOfBirth
                }
              />
              <label for="floatingInputGroup1">Guardian Name</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.name !== null
                    ? data.nominee[0]?.guardian?.name
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian Address</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.address !== null
                    ? data.nominee[0]?.guardian?.address
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian IdProof</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.idProof !== null
                    ? data.nominee[0]?.guardian?.idProof
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">Guardian IdProof No.</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.idProofNo !== null
                    ? data.nominee[0]?.guardian?.idProofNo
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1">
                Guardian Relation With Nominee
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[0]?.guardian?.relationWithNominee !== null
                    ? data.nominee[0]?.guardian?.relationWithNominee
                    : "NaN"
                }
              />
              <label for="floatingInputGroup1"> Type of Document</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 0 &&
                  data.nominee[2]?.typeOfDocument
                }
              />
              <label for="floatingInputGroup1">POI Reference Number</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.poiReferenceNumber
                }
              />
              <label for="floatingInputGroup1">Relation</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.relation
                }
              />
              <label for="floatingInputGroup1">Address 1</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.addressDto?.houseNumber
                }
              />
              <label for="floatingInputGroup1">Street</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.addressDto?.street
                }
              />
              <label for="floatingInputGroup1">City</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.addressDto?.district
                }
              />
              <label for="floatingInputGroup1">PinCode</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.addressDto?.pincode
                }
              />
              <label for="floatingInputGroup1">State</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.addressDto?.state
                }
              />
              <label for="floatingInputGroup1">Country</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.addressDto?.country
                }
              />
              <label for="floatingInputGroup1">Nominee Percentage</label>
              <input
                type="text"
                className="form-control"
                disabled
                id="floatingInputGroup1"
                value={
                  data?.nominee &&
                  data?.nominee.length > 2 &&
                  data.nominee[2]?.sharePercentage
                }
              />
            </div>
          ) : (
            <div className="text-start fw-bold fs-5">Nominee 3 not added</div>
          )}
          {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Nominee;
