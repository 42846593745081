import { useState, useEffect } from "react";
import useSessionStorage from "./useSession";

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useSessionStorage("token", "");

  const postData = async (
    url,
    data,
    isPatch = false,
    headers = {},
    BASE_URL = "https://vramathkyc.finovo.tech:4590/app2/api/v1/"
  ) => {
    setLoading(true);
    setError(null);
    try {
      if (token) {
        headers["xuserid"] = `${token}`;
      }

      // Add authToken to headers if available in sessionStorage
      const authToken = sessionStorage.getItem("authToken");
      if (authToken) {
        // headers["Authorization"] = authToken;
        console.log("===>",authToken);
      }
console.log("=>",authToken);
      const response = await fetch(`${BASE_URL}${url}`, {
        method: `${isPatch ? "PATCH" : "POST"}`,
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 401) {
        sessionStorage.removeItem("token");
        sessionStorage.clear();
        window.location.reload();
        alert("SESSION TIMEOUT");
      }

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const result = await response.json();

      // Save userid in session storage
      if (result?.userid) {
        setToken(result?.userid ?? "");
        // sessionStorage.setItem("token", result?.userid);
      }

      return result;
    } catch (error) {
      setError(error.message);
      return error;
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (url, headers = {}, BASE_URL = "https://vramathkyc.finovo.tech:4590/app2/api/v1/") => {
    setLoading(true);
    setError(null);
    try {
      if (token) {
        headers["xuserid"] = token;
      }

      // Add authToken to headers if available in sessionStorage
      const authToken = sessionStorage.getItem("authToken");
      if (authToken) {
        headers["Authorization"] = authToken;
      }

      const response = await fetch(`${BASE_URL}${url}`, {
        method: "GET",
        headers: { ...headers },
      });

      if (response.status === 401 ) {
        sessionStorage.removeItem("token");
        sessionStorage.clear();
        sessionStorage.removeItem("kycStatus");
        window.location.reload();
        alert("SESSION TIMEOUT");
      }

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, postData, fetchData };
};

export default useApi;
