import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import PdfPreview from "../../components/pdfViewer";
import { ToastContainer, toast } from "react-toastify";



const Nominee2 = ({ data, updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);


  const handleConsole = () => {
    const newRem = remark === "" ? "" : `<b>SECOND NOMINEE PROOF</b>${remark}`
    updateRejectData({ NOMINEE_TWO: newRem });
  }
  useEffect(() => {
    const storedRemark = localStorage.getItem("nominee2");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("nominee2", remark);
  }, [remark]);



  return (
    <div className="border border-2">
      <ToastContainer />
      <div className="head-container">
        <div className="reject-container p-2 text-start">
          <label for="floatingInputGroup1">
            <b>Reject Remark</b>
          </label>
          <input
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
            onChange={(e) => setRemark(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center">
          <Button onClick={handleConsole} disabled={isRejectButtonDisabled}>Reject</Button>{' '}
        </div>
        {/* <div className="input-file p-2 text-start gap-2">
          <label for="floatingInputGroup1">
            <b>
              Nominee proof (only png.jpg.pdf) <br />
              (file should be less than 4 MB)
            </b>
          </label>
          <input type="file" id="floatingInputGroup1" />
          <button className="btn btn-danger m-auto ">Upload</button>
        </div> */}
      </div>
      <div className="document-container">
        <div className="panImg-container">
        {data?.nomineeDetails[1]?.proofOfAddress ? (
          data?.nomineeDetails[1]?.proofOfAddress.startsWith("data:image") ? (
            <img src={data?.nomineeDetails[1]?.proofOfAddress} alt="" />
          ) : (
            <PdfPreview base64String={data?.nomineeDetails[1]?.proofOfAddress} />
          )
        ) : ""}
        </div>
      </div>
    </div>
  );
};

export default Nominee2;
