import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import {
  ADMIN_BASE_URL,
  ADMIN_BACKOFFICE_BASE_URL,
  REDIRECT_URL,
} from "../../constant/constants";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./styles.css"
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import PdfPreview from "../../components/pdfViewer";
import Excel from "../../assest/Excel.png"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiSearch } from "react-icons/ci";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FILTER_DATA } from "../../constant/MapperConstant";
import useSessionStorage from "../../hooks/useSession";
const ClientListing = () => {
  const navigation = useNavigate();
  const [data, setData] = React.useState({ totalPage: 1 });
  const [loader, setLoader] = useState(false)
  const [token, setToken] = useSessionStorage("token", "");
  const [filteredData, setFilteredData] = React.useState([]);
  const [searchData, setSearchData] = React.useState({
    search: "",
    page: 1,
    filter: "",
  });
  const { loading, fetchData } = useApi();
  const [base64String, setBase64String] = React.useState("");
  const [modal, setModal] = React.useState(false)
  const [show, setShow] = React.useState(false);
  const [aadharXml, setAadharXml] = React.useState("")
  const [fullscreen, setFullscreen] = React.useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  async function getClientList() {
    try {
      const data = await fetchData(
        `admin/users/filter?search=${searchData.search}&status=${searchData.filter}&page=${searchData.page}`,
        {},
        ADMIN_BASE_URL
      );
      setData(data.pageDetail);
      setFilteredData(data?.userAdminPageContent ?? []);
    } catch (e) {
      toast.error("Error while fetching data");
    }
  }


  React.useEffect(() => {
    getClientList();
  }, [searchData.page, searchData.search, searchData.filter]);

  const getDataTime = (epoch) => {
    const date = new Date(+epoch);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  const handleApiCalls = async (url, BASE_URL, successMsg, errorMsg) => {
    const baseUrl = BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      toast.success(successMsg);
      return data;
    } catch (e) {
      toast.error(errorMsg);
      throw e; // Re-throw the error to be caught by the calling function
    }
  };

  const handleRedirectToDetails = (clientId, phoneNumber) => {
    navigation(`/client/${phoneNumber}`, {
      state: { clientId: clientId, phoneNumber: phoneNumber },
    });
  };
  const handleGeneratePdf = async (userid_id) => {
    try {
      setLoader(true);
      const response = await fetch('https://vramathkyc.finovo.tech:4589/app1/api/v1/nsdl/esign/admin/pdf', {
        method: 'GET', // Assuming POST, change if needed
        headers: {
          'Content-Type': 'application/json', // Adjust as per the API requirements
          'xuserid': userid_id
        },
        // Body or other configurations can be added here if needed
      });
  
      const responseData = await response.json();
  
      console.log(responseData, "============================");
  
      if (responseData && responseData.pdfByte) {
        // Extract base64 string from the 'response' property
        const base64String = responseData.pdfByte;
  
        console.log(base64String, "-------------------------");
  
        setBase64String(base64String);
        toast.success("PDF generated successfully");
      } else {
        console.log("Invalid response format");
        toast.error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error in handleGeneratePdf:", error);
      toast.error("Failed to generate PDF");
    } finally {
      setLoader(false);
    }
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const handleExportData = async (
    url,
    BASE_URL,
    successMsg,
    errorMsg,
    phoneNumber
  ) => {
    const data = await handleApiCalls(url, BASE_URL, successMsg, errorMsg);
    openInNewTab(`${REDIRECT_URL}aadhar/${phoneNumber}`);
    openInNewTab(`${REDIRECT_URL}esigned/${phoneNumber}`);
    openInNewTab(`${REDIRECT_URL}audit-trail/${phoneNumber}`);
  };

  const handleFilter = (value) => {
    if (value === "ALL") {
      setSearchData((prev) => ({ ...prev, page: 1, filter: "" }));
    } else {
      setSearchData((prev) => ({ ...prev, page: 1, filter: value }));
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value?.trim() ?? "";
    if ((e.key === "Enter" && searchValue) || (!searchValue && searchData.search)) {
      setSearchData((prev) => ({ ...prev, search: searchValue, page: 1 }));
    }
    e.preventDefault();
  };
  const handleSaveClick = () => {
    // Create a Blob with the state data
    const blob = new Blob([aadharXml], { type: 'text/plain' });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'aadharXml.xml';

    // Append the link to the body and click it
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);

    console.log('State saved successfully!');
  };
  const handleBse = async (userid_id) => {
    const storedToken = sessionStorage.getItem('authToken');
    const url = 'https://vramathkyc.finovo.tech:4590/app2/api/v1/kyc/push/bse';

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'xuserid': userid_id,
          'Authorization': storedToken,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(`BSE ${responseData.status}`);
      } else {
        toast.error(`BSE ${responseData.message}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // No need to parse response.json() again here
    } catch (error) {
      console.error('Error fetching data:', error.message);
      // You can add a toast message for the error if needed
      // toast.error('Error fetching data:', error.message)
    } finally {
      setLoader(false);
    }
  };
  const handleCdsl = async (userid_id) => {
    const storedToken = sessionStorage.getItem('authToken');
    const url = 'https://vramathkyc.finovo.tech:4590/app2/api/v1/kyc/push/cdsl';

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'xuserid': userid_id,
          'Authorization': storedToken,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(`CDSL ${responseData.status}`);
      } else {
        toast.error(`CDSL ${responseData.message}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // No need to parse response.json() again here
    } catch (error) {
      console.error('Error fetching data:', error.message);
      // You can add a toast message for the error if needed
      // toast.error('Error fetching data:', error.message)
    } finally {
      setLoader(false);
    }
  };


  const handleNse = async (userid_id) => {
    const storedToken = sessionStorage.getItem('authToken');
    const url = 'https://vramathkyc.finovo.tech:4590/app2/api/v1/kyc/push/nse';

    try {
      setLoader(true)
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'xuserid': userid_id,
          'Authorization': storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`NSE ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoader(false)
    }
  }
  const handleBackOffice = async (userid_id) => {
    const storedToken = sessionStorage.getItem('authToken');
    const url = 'https://vramathkyc.finovo.tech:4590/app2/api/v1/kyc/push/backoffice';

    try {
      setLoader(true)
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'xuserid': userid_id,
          'Authorization': storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`BackOffice ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoader(false)
    }
  }

  const handleKRA = async (userid_id) => {
    // toast.success("KRA Successful");
    const storedToken = sessionStorage.getItem('authToken');
    const url = 'https://vramathkyc.finovo.tech:4590/app2/api/v1/kyc/push/kra';

    try {
      setLoader(true)
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'xuserid': userid_id,
          'Authorization': storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`KRA ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoader(false)
    }
  }
  const handleComplete = async (phoneNumber) => {
    const storedToken = sessionStorage.getItem('authToken');
    const url = `https://vramathkyc.finovo.tech:4590/app2/api/v1/feature/additional/complete/${phoneNumber}`;
    try {
      setLoader(true);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`Complete ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log(responseData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoader(false);
    }
  }
  const handleReEsign = async (phoneNumber) => {
    const storedToken = sessionStorage.getItem('authToken');
    const url = `
    https://vramathkyc.finovo.tech:4590/app2/api/v1/ekyc/user/leegality/notification/${phoneNumber}`;

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`RE-ESIGN ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log(responseData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoader(false);
    }
  }

  const handleCKYC = async (phoneNumber) => {
    const storedToken = sessionStorage.getItem('authToken');
    const url = `https://vramathkyc.finovo.tech:4590/app2/api/v1/download/ckyc/zip/folder/${phoneNumber}`;

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': storedToken,
        },
        responseType: 'blob', // This is not needed for fetch, but if you were using axios, you'd set responseType to 'blob'
      });

      if (response.ok) {
        // Assuming the server returns a ZIP file as a response
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = "ckyc_data.zip"; // This is the file name that will be used for the downloaded file
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(downloadUrl); // Clean up the URL object
        toast.success('Download started...');
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoader(false);
    }
  };
  const FetchData = async () => {
    setLoader(true)
    try {
      const storedToken = sessionStorage.getItem('authToken');
      const response = await fetch('https://vramathkyc.finovo.tech:4590/app2/api/v1/download/excel/client-data-report', {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      // Download the response
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // Set response content if needed
      // const jsonData = await response.json();
      // setResponseContent(jsonData);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false)
    }
  };

  const handleDownload = () => {
    console.log("===========>");
    setShowPopup(true);
  };

  const handleDownloadAll = async () => {
    await fetchData();
  };

  const handleSubmit = async () => {
    const fromEpoch = new Date(fromDate).getTime();
    const toEpoch = new Date(toDate).getTime();
    const storedToken = sessionStorage.getItem('authToken');
    const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/download/excel/client-data-report?from=${fromEpoch}&to=${toEpoch}`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setShowPopup(false);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const FetchDataInfo = async () => {
    setLoader(true)
    try {
      const storedToken = sessionStorage.getItem('authToken');
      const response = await fetch('https://vramathkyc.finovo.tech:4590/app2/api/v1/download/excel/editLogs', {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      // Download the response
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false)
    }
  };

  const handleInfoDownload = () => {
    console.log("===========>");
    setShowInfoPopup(true);
  };

  const handleInfoDownloadAll = async () => {
    await fetchData();
  };

  const handleInfoSubmit = async () => {
    const fromEpoch = new Date(fromDate).getTime();
    const toEpoch = new Date(toDate).getTime();
    const storedToken = sessionStorage.getItem('authToken');
    const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/download/excel/editLogs?from=${fromEpoch}&to=${toEpoch}`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setShowInfoPopup(false);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };



  return (
    <div>
      <Loader open={loading} />
      <Loader open={loader} />
      <Modal
        size="lg"
        dialogClassName="modal-90w modal-90h"
        show={Boolean(base64String)}
        onHide={() => setBase64String("")}
      >
        <div> mnvmjhv,jvkjhb;</div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        fullscreen={fullscreen}
      >
        <Modal.Header closeButton>
          <Modal.Title><i>Aadhar Xml</i> <hr /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {aadharXml}

        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <Button variant="primary" onClick={handleSaveClick}>Download</Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
      <Loader open={loading} />
      {base64String ? (
        <Modal
          size="lg"
          dialogClassName="modal-90w modal-90h"
          show={Boolean(base64String)}
          onHide={() => setBase64String("")}
        >
          <PdfPreview base64String={base64String} />
        </Modal>
      ) : (
        <></>
      )}
      <ToastContainer />
      <Container fluid>
        <Row>
        <div className="d-flex justify-content-around gap-5 align-items-center ">
            <DropdownButton
              variant={searchData.filter ? "success" : "info"}
              className="my-3"
              id="dropdown-basic-button"
              title={searchData?.filter ? searchData?.filter : "Select Filter"}
            >
              {FILTER_DATA.map((item, idx) => (
                <Dropdown.Item
                  key={item.id}
                  onClick={() => handleFilter(item.id)}
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <div className="w-25 d-flex">
              <input onKeyUpCapture={handleSearch} className="w-100 py-2 p-1 rounded-pill rounded-end-0 border border-end-0" active="border-0" placeholder="Search here..." />
              <div className="bg-white pt-1  rounded-pill rounded-start-0">
                <CiSearch className="fs-3 me-3" />
              </div>
            </div>
            <div className="w-25 d-flex">
            <button className="bg-white p-2 rounded border-2 border-black" onClick={FetchDataInfo}>
                Info Logs All &nbsp;
                <img src={Excel} className="excel-logo" />
              </button>
              <button className="bg-white p-2 rounded  border-2 border-black" onClick={handleInfoDownload}>Edit Logs Custom <img src={Excel} className="excel-logo" /></button>
            </div>

            <div className="gap-5">
              <button className="bg-white p-2 rounded border-2 border-black" onClick={FetchData}>
                Download All &nbsp;
                <img src={Excel} className="excel-logo" />
              </button>
              <button className="bg-white p-2 rounded  border-2 border-black" onClick={handleDownload}>Download <img src={Excel} className="excel-logo" /></button>
            </div>
            {showInfoPopup && (
              <div
                className={`w-full h-screen flex justify-center items-center border border-2 border-dark `}
                style={{
                  position: "fixed",
                  cursor: "pointer",
                  top: "25%",
                  left: "60%",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                  backgroundColor: "skyblue",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  boxShadow:
                    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                }}
              >
                <span className="text-black fw-semibold fs-4"><u>Info Logs</u></span>
                <div className="bg-secondary rounded float-end" style={{ width: "25px", position: "absolute", right: "10px", top: "10px", fontSize: "20px", color: "white"}} onClick={() => { setShowInfoPopup(false) }} >X</div>
                <div className="popup d-flex">
                  <div className="d-flex flex-column">
                    <label htmlFor="fromDate" className="fs-5 fw-bold border-dark"><u>From:</u></label>
                    <input className="m-2 rounded p-1" type="date" id="fromDate" value={fromDate} onChange={(e) => setFromDate(e.target.value)} /><br />
                  </div>
                  <div className="d-flex flex-column">
                    <label htmlFor="toDate" className="fs-5  fw-bold border-dark"><u>To:</u></label>
                    <input className="m-2 rounded p-1" type="date" id="toDate" value={toDate} onChange={(e) => setToDate(e.target.value)} /><br />
                  </div>
                </div>
                <button onClick={handleInfoSubmit} className="p-1 rounded bg-primary text-white mt-4 fs-5">Submit</button>
              </div>
            )}
            {showPopup && (
              <div
                className={`w-full h-screen flex justify-center items-center border border-2 border-dark `}
                style={{
                  position: "fixed",
                  cursor: "pointer",
                  top: "25%",
                  left: "85%",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                  backgroundColor: "skyblue",
                  padding: "2rem",
                  borderRadius: "0.5rem",
                  boxShadow:
                    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                }}
              >
                <div className="bg-secondary rounded float-end" style={{ width: "25px", position: "absolute", right: "10px", top: "10px", fontSize: "20px", color: "white"}} onClick={() => { setShowPopup(false) }} >X</div>
                <div className="popup d-flex">
                  <div className="">
                    <label htmlFor="fromDate" className="fs-5 fw-bold border-bottom border-dark">From:</label>
                    <input className="m-2 rounded p-1" type="date" id="fromDate" value={fromDate} onChange={(e) => setFromDate(e.target.value)} /><br />
                  </div>
                  <div>
                    <label htmlFor="toDate" className="fs-5  fw-bold border-bottom border-dark">To:</label>
                    <input className="m-2 rounded p-1" type="date" id="toDate" value={toDate} onChange={(e) => setToDate(e.target.value)} /><br />
                  </div>
                </div>
                <button onClick={handleSubmit} className="p-1 rounded bg-primary text-white mt-4 fs-5">Submit</button>
              </div>
            )}
          </div>

          <Col>
            <Table striped bordered hover responsive>
              <thead className="bg-light border border-light">
                <tr>
                  <th>Action</th>
                  <th>Stage Change</th>
                  <th>Reference Code</th>
                  <th>Branch</th>
                  <th>Client Code</th>
                  <th>PAN</th>
                  <th>STATUS</th>
                  <th>Name</th>
                  <th>Mo No.</th>
                  <th>Payment Status</th>
                  <th>Entry Time</th>
                  <th className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"}>BACKOFFICE</th>
                  <th></th>
                  <th className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"}>NSE</th>
                  <th></th>
                  <th className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block " : "d-none"}>BSE</th>
                  <th></th>
                  <th className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block " : "d-none"}>CDSL</th>
                  <th></th>
                  <th className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"}>KRA</th>
                  {/* <th></th> */}
                  <th>Generate Pdf</th>
                  <th>Export</th>
                  <th className={searchData.filter === "USER_PROCESS_COMPLETED" || searchData.filter === "THANK_YOU" ? "d-block" : "d-none"}>CKYC</th>
                  <th></th>
                  <th className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"}>Complete</th>
                  <th className={searchData.filter === "E_SIGN" ? "d-block" : "d-none"}>Resend Esign</th>
                </tr>
              </thead>
              {filteredData && filteredData.length ? (
                <tbody align="center" className="bg-light  border border-light ">
                  {filteredData.map((row) => (
                    <tr key={row.phoneNumber}>
                      <td>
                        <Button
                          onClick={() =>
                            handleRedirectToDetails(
                              row.clientCode,
                              row.phoneNumber
                            )
                          }
                          variant="primary"
                        >
                          {"Verify"}
                        </Button>
                      </td>{" "}
                      <td>{row.kycStatus}</td>
                      <td>{row.referenceCode}</td>
                      <td>{row.branch}</td>
                      <td>{row?.clientCode ?? "N/A"}</td>
                      <td>{row?.panCard ?? "N/A"}</td>
                      <td>{row.kycStatus}</td>
                      <td>{row.customerName}</td>
                      <td>{row.phoneNumber}</td>
                      <td>{row?.paymentStatus ?? "N/A"}</td>
                      <td>{getDataTime(row?.dateEntryTime)}</td>


                      <td className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block " : "d-none"}>
                        <Button
                          // disabled={row.kycStatus === "false"}
                          onClick={() => {
                            handleBackOffice(row.userid);
                          }}
                          variant="success"

                        >
                          Complete BackOffice
                        </Button>
                      </td>
                      <td ></td>
                      <td className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"} >
                        <Button
                          // disabled={row.kycStatus === "false"}
                          // disabled
                          onClick={() => {
                            handleNse(row.userid);
                          }}
                          variant="success"

                        >
                          Complete NSE
                        </Button>
                      </td>
                      <td></td>
                      <td className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"}>
                        <Button
                          // disabled={row.rekycStatus === "false"}
                          // disabled
                          onClick={() => {
                            handleBse(row.userid);
                          }}
                          variant="success"
                        >
                          Complete BSE
                        </Button>
                      </td>
                      <td></td>
                      <td className={`${searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"} d-flex align-items-center`} >
                        <Button
                          // disabled={row.kycStatus === "false"}
                          // disabled={true}
                          onClick={() => {
                            handleCdsl(row.userid);
                          }}
                          variant="success"
                        >
                          Complete CDSL
                        </Button>
                      </td>
                      <td></td>
                      <td className={`d-flex align-items-center ${searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"} `}>
                        <Button
                          // disabled={row.kycStatus === "false"}
                          // disabled
                          onClick={() => handleKRA(row.userid)}
                          variant="success"
                          className="py-3"

                        >
                        CVLKRA
                        </Button>
                      </td>
                      {/* <td></td> */}
                      <td>
                        <Button
                          // disabled={row.clientCode === "NULL"}
                          onClick={() =>
                            handleGeneratePdf(row.userid)
                          }
                          variant="danger"
                        >
                          Generate PDF
                        </Button>
                      </td>
                      <td>
                        <Button
                          disabled={row.clientCode === "NULL"}
                          onClick={() => {
                            const authToken = sessionStorage.getItem("authToken");
                            const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/export/button/${row.phoneNumber}`;
                            setLoader(true)
                            fetch(apiUrl, {
                              method: 'GET',
                              headers: {
                                'Authorization': authToken,
                                'Content-Type': 'application/json', 
                              },
                            })
                              .then(response => {
                                if (response.status === 400) {
                                  toast.error("E-Sign document id not available")
                                }
                                if (!response.ok) {
                                  setLoader(false)
                                  throw new Error(`HTTP error! Status: ${response.status}`);
                                }
                                return response.json();
                              })
                              .then(data => {
                                const downloadFile = (dataContent, fileName, mimeType) => {
                                  let blob;
                                  if (mimeType === 'text/xml') {
                                    blob = new Blob([dataContent], { type: mimeType });
                                  } else {
                                    const binaryData = atob(dataContent);
                                    const arrayBuffer = new ArrayBuffer(binaryData.length);
                                    const uint8Array = new Uint8Array(arrayBuffer);
                                    for (let i = 0; i < binaryData.length; i++) {
                                      uint8Array[i] = binaryData.charCodeAt(i);
                                    }
                                    blob = new Blob([uint8Array], { type: mimeType });
                                  }
                                  const downloadLink = document.createElement('a');
                                  downloadLink.href = URL.createObjectURL(blob);
                                  downloadLink.download = fileName;
                                  document.body.appendChild(downloadLink);
                                  downloadLink.click();
                                  document.body.removeChild(downloadLink);
                                };
                                if (data.auditTrailPdf) {
                                  downloadFile(data.auditTrailPdf, 'auditTrailPdf.pdf', 'application/pdf');
                                }
                                if (data.esigned) {
                                  downloadFile(data.esigned, 'esignedPdf.pdf', 'application/pdf');
                                }
                                if (data.aadharXml) {
                                  downloadFile(data.aadharXml, 'aadharData.xml', 'text/xml');
                                }
                                setLoader(false)
                              })
                              .catch(error => {
                                setLoader(false)
                                console.error('Error:', error);
                              });
                          }}
                          variant="success"
                        >
                          Export
                        </Button>
                      </td>
                      <td className={searchData.filter === "USER_PROCESS_COMPLETED" || searchData.filter === "THANK_YOU" ? "d-block" : "d-none"} >
                        <Button
                          onClick={() => handleCKYC(row.phoneNumber)}
                        >
                          CKYC
                        </Button>
                      </td>
                      <td></td>
                      <td className={searchData.filter === "USER_PROCESS_COMPLETED" ? "d-block" : "d-none"} >
                        <Button
                          onClick={() => handleComplete(row.phoneNumber)}
                        >
                          Complete
                        </Button>
                      </td>


                      <td className={searchData.filter === "E_SIGN" ? "d-block" : "d-none"} >
                        <Button
                          onClick={() => handleReEsign(row.phoneNumber)}
                        >
                          Resend Esign
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <p className="no-data-available">No Data Available</p>
              )}
            </Table>
          </Col>
        </Row>
        <Pagination className="px-4">
          {Array(+data?.totalPage ?? 1)?.fill(1)?.map((_, index) => {
            return (
              <Pagination.Item
                onClick={() =>
                  setSearchData((prev) => ({ ...prev, page: index + 1 }))
                }
                key={index + 1}
                active={index + 1 === searchData.page}
              >
                {index + 1}
              </Pagination.Item>
            );
          })}
        </Pagination>
      </Container>
    </div>
  );
};

export default ClientListing;
