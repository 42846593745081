import React, { useState , useEffect} from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import PdfPreview from "../../components/pdfViewer";
import Button from 'react-bootstrap/Button';

const FinancialProof = ({ data , updateRejectData }) => {
  const { loading, postData } = useApi();
  const [remark, setRemark] = useState("");
  const [imageData, setImageData] = useState("");

  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            financialProof: imageData,
          }
        );
        await toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const handleApiCall = async () => {
    if (remark?.length > 10) {
      try {
        const res = await postData(
          `reject/upload/${data?.userDetail?.phoneNumber}`,
          {
            FINANCIAL_PROOF: remark,
          }
        );
        toast.success("Rejected successfully");
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>FINENCIAL PROOF</b>${remark}`
    updateRejectData({ FINANCIAL_PROOF: newRem });
    setRejectButtonDisabled(true);
    toast.success("Reject Added")
  }

  useEffect(() => {
    const storedRemark = localStorage.getItem("finentialProof");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("finentialProof", remark);
  }, [remark]);

  // const signatureValue = data?.rejectionList.find(item => item.startsWith("FINANCIAL_PROOF:")) || "";
  const signatureValue = (data?.rejectionList || []).find(item => item.startsWith("FINANCIAL_PROOF:")) || "";
  const inputValue = signatureValue.replace("FINANCIAL_PROOF:", "") ? signatureValue.replace("FINANCIAL_PROOF:", "") : remark;
 
  return (
    <div className="border border-2">
      <Loader open={loading} />
      <ToastContainer />
      <div className="head-container">
        <div className="reject-container p-2 text-start">
          <label for="floatingInputGroup1">
            <b
              className={`${
                Boolean(data?.rejectedDocumentDetails?.FINANCIAL_PROOF)
                  ? "error-text"
                  : ""
              }`}
            >
              Reject Remark
            </b>
          </label>
          <input
            // disabled={Boolean(data?.rejectedDocumentDetails?.FINANCIAL_PROOF)}
            value={inputValue}
            onChange={(e) => setRemark(e.target.value)}
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
          />
        </div>
        <div className="d-flex align-items-center">
        <Button onClick={handleConsole} disabled={inputValue == signatureValue.replace("FINANCIAL_PROOF:", "")}>Reject</Button>{' '}
        </div>
        {/* <div className="input-file p-2 text-start gap-2">
          <label for="floatingInputGroup1">
            <b>
              Financial proof (only png.jpg.pdf) <br />
              (file should be less than 4 MB)
            </b>
          </label>
          <input
            type="file"
            name="financialProof"
            onChange={onChange}
            accept=".pdf"
          />
          {imageData && (
            <>
              <div className="w-1/4 h-50 p-4">
                <img
                  className="w-full"
                  src={imageData}
                  alt="bank validation proof"
                />
              </div>
              <button
                variant="outlined"
                color="error"
                onClick={() => setImageData("")}
              >
                Cancel
              </button>
            </>
          )}
          <button onClick={handleUploadData} className="btn btn-danger m-auto ">
            Upload
          </button>
        </div> */}
      </div>
      <div className="document-container">
        {/* <div className="panImg-container">
          <img
            src={data?.bankDetails?.bankValidationProof}
            alt=""
            width="100%"
            height="100%"
          />
        </div> */}
        {/* {data?.rekycUserRequestedDetails?.financialProof ? (
          // <PdfPreview base64String={data?.userInformation?.financialProofDocument ?? ""} />
          <img src={data?.rekycUserRequestedDetails?.financialProof} alt="" width="70%" />
        ) : (
          ""
        )} */}
        {data?.rekycUserRequestedDetails?.financialProof ? (
           data?.rekycUserRequestedDetails?.financialProof.startsWith("data:image") ? (
             <img src={data?.rekycUserRequestedDetails?.financialProof} alt="" />
            ) : (
              // <img
              // src={`data:image/jpeg;base64,${data?.rekycUserRequestedDetails?.signature}`}
              // alt=""
              // width="100%"
              // height="100%"
              //   />
                <PdfPreview base64String={data?.rekycUserRequestedDetails?.financialProof ?? ""} />
            )
          ) : ""}
      </div>
    </div>
  );
};

export default FinancialProof;
