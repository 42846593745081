import React from "react";
import "./styles.css";

const Address = ({ data }) => {
  return (
    <>
      <div className="head-text">
        <p className="text-start fw-bold fs-5">Permanent Address</p>
        {/* <p className="fs-5 fw-bold">Action</p> */}
      </div>
      <div className="permanent-address fw-semibold">
        <div className="text-start">
          <label for="floatingInputGroup1">New Address</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="floatingInputGroup1"
            value={data?.aadharData?.aadharAddressDto?.houseNumber}
          />
          <label for="floatingInputGroup1">New Address Line 2</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="floatingInputGroup1"
            value={data?.aadharData?.aadharAddressDto?.location}
          />
          {/* <label for="floatingInputGroup1">Voting Center</label> */}
          {/* <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.votingCenter} /> */}
          <label for="floatingInputGroup1">New Country</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="floatingInputGroup1"
            value={data?.aadharData?.aadharAddressDto?.country}
          />
          <label for="floatingInputGroup1">New State</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="floatingInputGroup1"
            value={data?.aadharData?.aadharAddressDto?.state}
          />
          <label for="floatingInputGroup1">New City</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="floatingInputGroup1"
            value={data?.aadharData?.aadharAddressDto?.district}
          />
          <label for="floatingInputGroup1">New PinCode</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="floatingInputGroup1"
            value={data?.aadharData?.aadharAddressDto?.pincode}
          />
          {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export { Address };
