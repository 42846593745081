import React from "react"
import "./styles.css"
import {useParams} from "react-router-dom"

const BasicDetails = ({data}) => {
  const {clientId} = useParams();
  return (
    <>
      <div className="head-text">
        <p className="text-start fw-bold fs-5">Basic Details</p>
        {/* <p className="fs-5 fw-bold">Action</p> */}
      </div>
      <div className="Basic-details fw-semibold">
        <div className="text-start">
          <label for="floatingInputGroup1">Name</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.backOfficeDetails?.name}/>
          <label for="floatingInputGroup1">Father's/ Husband's Name</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.backOfficeDetails?.fatherName ??""}/>
          <label for="floatingInputGroup1">Gender</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.backOfficeDetails?.gender ??""}/>
          <label for="floatingInputGroup1">Email ID</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.backOfficeDetails?.email ?? ""}/>
          <label for="floatingInputGroup1">New Email ID</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.rekycUserRequestedDetails?.newEmail ?? ""}/>
          <label for="floatingInputGroup1">Mobile Number</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.backOfficeDetails?.phoneNumber}/>
          <label for="floatingInputGroup1">New Mobile Number</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.rekycUserRequestedDetails?.newMobile}/>
          {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default BasicDetails
