import React, { useState } from "react";
import { STEPPER_TABS } from "../../constant/MapperConstant";
import { Address } from "../../layout/address";
import Other from "../../layout/otherInfo";
import Nominee from "../../layout/nominee";
import "./styles.css";
import BasicDetails from "../../layout/basicDetail";
import BankDetails from "../../layout/bankDetails";

const Stepper = ({ clientData }) => {
  const [activeTab, setActiveTab] = useState(STEPPER_TABS[0]);

  const handleOnClick = (item) => {
    setActiveTab(item);
  };

  const renderActiveComponent = () => {
    switch (activeTab.id) {
      case 1:
        return <BasicDetails data={clientData} />;
      case 2:
        return <Address data={clientData} />;
      case 3:
        return <BankDetails data={clientData} />;
      case 4:
        return <Other data={clientData} />;
      case 5:
        return <Nominee data={clientData} />;
      default:
        return null;
    }
  };
  return (
    <>
      <div className="navbar navbar-expand-lg navbar-light bg-light m-0 p-0 fw-bold overflow-x-auto overflow-y-hidden">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav bg-info p-3">
            {STEPPER_TABS.map((item) => (
              <li
                className={`stepper-tab ${
                  item.id === activeTab.id
                    ? "active-tab text-white bg-primary rounded-pill"
                    : ""
                }`}
                onClick={() => handleOnClick(item)}
                key={item.id}
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {activeTab && renderActiveComponent()}
    </>
  );
};

export { Stepper };
