import React from 'react'
import Logo from "../../assest/nirmanLogo.png"
import Button from 'react-bootstrap/Button';


const Navbar = () => {
  const LogOut =() =>{
    // const login = window.localStorage.setItem("isLoggedIn", false);
    // console.log("-----------------------", login)
    window.localStorage.setItem("isLoggedIn", false);
    const login = window.localStorage.getItem("isLoggedIn");
    sessionStorage.clear();
    console.log("-----------------------", login)
    window.location.reload();
    // navigation('/')
  }
  return (
    <div>
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid d-flex">
          <img src={Logo} className="ryz-logo img-fluid" alt="..." width="80px" />
          <Button className='btn btn-warning' variant="success" onClick={LogOut}>Sign Out</Button>
        </div>
      </nav>
    </div>
  )
}

export {Navbar}
