import React, { useState, useEffect } from "react";
import "./styles.css";
import useApi from "../../hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/loader";
import Button from 'react-bootstrap/Button';


const ClientSignature = ({ data,updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const { loading, error, postData, fetchData } = useApi();
  const [imageData, setImageData] = useState("");
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            clientSignature: imageData,
          }
        );
        toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.errror("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

      setImageData(reader.result);
    };
  };

  const handleApiCall = async () => {
    if (remark?.length > 10) {
      try {
        const res = await postData(
          `reject/upload/${data?.userDetail?.phoneNumber}`,
          {
            SIGNATURE: remark,
          }
        );
        toast.success("Rejected successfully");
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>SIGNATURE</b>${remark}`
    updateRejectData({ SIGNATURE: newRem });
    setRejectButtonDisabled(true);
    toast.success("Reject Added")
  }

  useEffect(() => {
    const storedRemark = localStorage.getItem("signature");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("signature", remark);
  }, [remark]);
 


  return (
    <div className="border border-2">
      <ToastContainer />
      <Loader open={loading} />
      <div className="head-container">
        <div className="reject-container p-2 text-start">
          <label for="floatingInputGroup1">
            <b
              className={`${
                Boolean(data?.rejectedDocumentDetails?.SIGNATURE) &&
                "error-text"
              }`}
            >
              Reject Remark
            </b>
          </label>
          <input
            disabled={Boolean(data?.rejectedDocumentDetails?.SIGNATURE)}
            value={data?.rejectedDocumentDetails?.SIGNATURE ?? remark}
            onChange={(e) => setRemark(e.target.value)}
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
          />
        </div>
        <div className="d-flex align-items-center">
        <Button onClick={handleConsole} disabled={isRejectButtonDisabled}>Reject</Button>{' '}
        </div>
        {/* <div className="input-file p-2 text-start gap-2">
          <label for="floatingInputGroup1">
            <b>
              Signature (only png.jpg.pdf) <br />
              (file should be less than 4 MB)
            </b>
          </label>
          <input
            type="file"
            name="clientSignature"
            onChange={onChange}
            accept=".jpeg, .jpg"
          />
          {imageData && (
            <>
              <div className="w-1/4 h-50 p-4">
                <img
                  className="w-full"
                  src={imageData}
                  alt="bank validation proof"
                />
              </div>
              <button
                variant="outlined"
                color="error"
                onClick={() => setImageData("")}
              >
                Cancel
              </button>
            </>
          )}
          <button onClick={handleUploadData} className="btn btn-danger m-auto ">
            Upload
          </button>
        </div> */}
      </div>
      <div className="document-container">
        <div className="panImg-container">
          <img
            src={data?.imageAndSignature?.signature}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientSignature;
