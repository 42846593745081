import React, { useState } from "react"
import axios from "axios"
import Loader from '../../components/loader';
import { GrEdit } from "react-icons/gr";
import "./styles.css"
import { ToastContainer, toast } from "react-toastify";


const Address = ({ data }) => {
  const [edit, setEdit] = useState(false)
  const [loading,setLoading]=useState(false)
  const [formData, setFormData] = useState({
    houseNumber: "",
    location: "",
    street: "",
    votingCenter: "",
    district: "",
    pincode: "",
    state: "",
    country: ""
  })
  const [error, setError] = useState(null);
  const handleEdit = () => {
    if (edit) {
      // If edit mode is already active, reset form data and close edit mode
      setFormData({
        houseNumber: "",
        location: "",
        street: "",
        votingCenter: "",
        district: "",
        pincode: "",
        state: "",
        country: ""
      });
      setEdit(false);
      setError("")
    } else {
      // If edit mode is not active, enable edit mode
      setEdit(true);
    }
  };

  const handleSubmit = () => {
    setLoading(true)
    const phoneNo = data.userDetail.phoneNumber;
    const storedToken = sessionStorage.getItem('authToken');
    const adminName = sessionStorage.getItem('admin');
    const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/user-data/edit-address/${phoneNo}?admin=${adminName}`;
    const headers = {
      'Authorization': storedToken,
    };
    
    axios.patch(apiUrl, formData, { headers })
      .then(response => {
        console.log('Data submitted successfully:', response.data);
        toast.success("Data submitted successfully")
        setEdit(false);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error submitting data:', error);
        toast.error("Error submitting data")
      });
  };
 
  return (
    <>
       <Loader open={ loading} />
          {edit && (
      <div
        className={`w-full h-screen border border-2 border-dark `}
        style={{
          position: "fixed",
          cursor: "pointer",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          backgroundColor: "navy",
          padding: "1rem",
          borderRadius: "0.5rem",
          width: "30rem",
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
      >
        <span className='fw-semibold text-white fs-4'><u>Edit your Permanent Address</u></span>
        <span className='bg-warning float-end text-black p fw-bold rounded-pill'
          style={{ width: "25px", fontSize: "20px", color: "white" }}
          onClick={handleEdit}
        >
          X
        </span>
        <div className='d-flex flex-column justify-content-center text-start ps-2 text-white '>
          <label htmlFor="houseNumber" className='fw-semibold'>Adderss line 1</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="houseNumber"
            onChange={(e) => setFormData({ ...formData, houseNumber: e.target.value })}
            value={formData.houseNumber}
          />
          <label htmlFor="street" className="fw-semibold">Address Line 2</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="street"
            onChange={(e) => setFormData({ ...formData, street: e.target.value })}
            value={formData.street}
          />
          <label htmlFor="location" className="fw-semibold">Location</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="location"
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
            value={formData.location}
          />
          <label htmlFor="district" className='fw-semibold'>City / District</label>
          <input
            type="text"
            className="form-control"
            id="district"
            placeholder='Enter here...'
            onChange={(e) => setFormData({ ...formData, district: e.target.value })}
            value={formData.district}
          />
          <label htmlFor="state" className='fw-semibold'>State</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="state"
            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
            value={formData.state}
          />
          <label htmlFor="country" className='fw-semibold'>Country</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="country"
            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
            value={formData.country}
          />
          <label htmlFor="pincode" className='fw-semibold'>Pincode</label>
          <input
            type="number"
            className="form-control no-spinner"
              placeholder='Enter here...'
              onWheel={(e) => e.target.blur()}
            id="pincode"
            onChange={(e) => setFormData({ ...formData, pincode: e.target.value })}
            value={formData.pincode}
          />
          <label htmlFor="votingCenter" className='fw-semibold'>Voting Center</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="votingCenter"
            onChange={(e) => setFormData({ ...formData, votingCenter: e.target.value })}
            value={formData.votingCenter}
          />
         
        </div>
        <button onClick={handleSubmit} className="p-1 rounded bg-primary text-white mt-4 fs-5">Submit</button>
      </div>
    )}
      <div className="head-text d-flex flex-row justify-content-between align-items-center p-1">
          <p className="text-start fw-bold fs-6">Permanent Address</p>
          <p className="d-flex align-items-center gap-2 border border-2" style={{ cursor: "pointer" }}
            onClick={handleEdit}
          >
            <GrEdit /> <span className='fw-bold fs-5'>Edit</span>
          </p>

        </div>

      
      <div className="permanent-address fw-semibold">
        <div className="text-start">
          <label for="floatingInputGroup1">Address</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.houseNumber} />
          <label for="floatingInputGroup1">Addess Line 2</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.location} />
          <label for="floatingInputGroup1">Voting Center</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.votingCenter} />
          <label for="floatingInputGroup1">District / City</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.district} />
          <label for="floatingInputGroup1">State</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.state} />
        
          <label for="floatingInputGroup1">Country</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.country} />
          <label for="floatingInputGroup1">PinCode</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.aadharAddressDto?.pincode} />
          {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export { Address }
