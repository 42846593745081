import React, { useState } from 'react'
import "./styles.css"
import { GrEdit } from "react-icons/gr";
import axios from 'axios';
import Loader from '../../components/loader';
import { ToastContainer, toast } from "react-toastify";

const BankDetails = ({ data }) => {
  const [edit, setEdit] = useState(false)
  const [loading,setLoading]=useState(false)
  const [formData, setFormData] = useState({
    bankRegisteredName: "",
    ifsc: "",
    accountNumber: "",
    micr: ""
  })
  const [error, setError] = useState(null);
  const handleEdit = () => {
    if (edit) {
      // If edit mode is already active, reset form data and close edit mode
      setFormData({
        micr: "",
        accountNumber: "",
        ifsc: "",
        bankRegisteredName: ""
      });
      setEdit(false);
      setError("")
    } else {
      // If edit mode is not active, enable edit mode
      setEdit(true);
    }
  };

  const handleSubmit = () => {
    setLoading(true)
    const phoneNo = data.userDetail.phoneNumber;
    const storedToken = sessionStorage.getItem('authToken');
    const adminName = sessionStorage.getItem('admin');
    
    const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/user-data/edit-bank/${phoneNo}?admin=${adminName}`;
    const headers = {
      'Authorization': storedToken,
    };
    if (formData.ifsc && formData.ifsc.trim() && (!formData.micr || !formData.micr.trim())) {
      setLoading(false)
      setError('IFSC and MICR field is required.');
      return false;
    }
    axios.patch(apiUrl, formData, { headers })
      .then(response => {
        console.log('Data submitted successfully:', response.data);
        toast.success("Data submitted successfully")
        setEdit(false);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error submitting data:', error);
        toast.error("Error submitting data")
        setLoading(false)
      });
  };
  


  return (
    <>
            <Loader open={ loading} />
      {edit && (
      <div
        className={`w-full h-screen border border-2 border-dark `}
        style={{
          position: "fixed",
          cursor: "pointer",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          backgroundColor: "navy",
          padding: "1rem",
          borderRadius: "0.5rem",
          width: "30rem",
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
      > 
        <span className='fw-semibold text-white fs-4'><u>Edit your Bank details</u></span>
        <span className='float-end bg-warning text-black p fw-bold rounded-pill'
          style={{ width: "25px", fontSize: "20px", color: "white" }}
          onClick={handleEdit}
        >
          X
        </span>
        <div className='d-flex flex-column justify-content-center text-start ps-2 text-white '>
          <label htmlFor="bankRegisteredName" className='fw-semibold'>Name as per Bank</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="bankRegisteredName"
            onChange={(e) => setFormData({ ...formData, bankRegisteredName: e.target.value })}
            value={formData.bankRegisteredName}
          />
          <label htmlFor="ifsc" className='fw-semibold' >IFSC</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="ifsc"
            onChange={(e) => setFormData({ ...formData, ifsc: e.target.value })}
            value={formData.ifsc}
          />
               {error && (<><span className='text-start'>{ error}*</span></>)}
          <label htmlFor="micr" className='fw-semibold'>MICR Code</label>
          <input
            type="text"
            className="form-control"
            id="micr"
            placeholder='Enter here...'
            onChange={(e) => setFormData({ ...formData, micr: e.target.value })}
            value={formData.micr}
          />
          <label htmlFor="accountNumber" className='fw-semibold'>Bank Account Number</label>
          <input
            type="text"
            className="form-control"
            placeholder='Enter here...'
            id="accountNumber"
            onChange={(e) => setFormData({ ...formData, accountNumber: e.target.value })}
            value={formData.accountNumber}
          />
        </div>
        <button onClick={handleSubmit} className="p-1 rounded bg-primary text-white mt-4 fs-5">Submit</button>
      </div>
    )}
      <div className='bankDetails-wrapper'>
        <div className="head-text d-flex flex-row justify-content-between align-items-center p-1">
          <p className="text-start fw-bold fs-5">Bank Details</p>
          <p className="d-flex align-items-center gap-2 border border-2" style={{ cursor: "pointer" }}
            onClick={handleEdit}
          >
            <GrEdit /> <span className='fw-bold fs-5'>Edit</span>
          </p>

        </div>
      <div className="text-start fw-semibold">
        <label htmlFor="nameInput">Name as per Bank</label>
        <input
          type="text"
          className="form-control" disabled
          id="nameInput"
          value={data?.bankDetails?.bankRegisteredName}
        />
        <label htmlFor="bankNameInput">Bank Name</label>
        <input
          type="text"
          className="form-control" disabled
          id="bankNameInput"
          value={data?.bankDetails?.bankName}
        />
        <label htmlFor="ifscInput">IFSC</label>
        <input
          type="text"
          className="form-control" disabled
          id="ifscInput"
          value={data?.bankDetails?.ifsc}
        />
        <label htmlFor="accountNumberInput">Bank Account Number</label>
        <input
          type="text"
          className="form-control" disabled
          id="accountNumberInput"
          value={data?.bankDetails?.accountNumber}
        />
        <label htmlFor="branchInput">Branch</label>
        <input
          type="text"
          className="form-control" disabled
          id="branchInput"
          value={data?.bankDetails?.branch ?? ""}
        />
        <label htmlFor="bankAddressInput">Bank Address</label>
        <input
          type="text"
          className="form-control" disabled
          id="bankAddressInput"
          value={data?.bankDetails?.address}
        />
        <label htmlFor="micrCodeInput">MICR Code</label>
        <input
          type="text"
          className="form-control" disabled
          id="micrCodeInput"
          value={data?.bankDetails?.micr}
        />
        <label htmlFor="pennyDropInput">Payout id of payment</label>
        <input
          type="text"
          className="form-control" disabled
          id="pennyDropInput"
          value={data?.bankDetails?.payoutId}
        />
        <label htmlFor="pennyReasonInput">Bank Verification Status</label>
        <input
          type="text"
          className="form-control" disabled
          id="pennyReasonInput"
          value={data?.bankDetails?.status ?? ""}
        />
                <label htmlFor="pennyReasonInput">Penny Drop Reason</label>
        <input
          type="text"
          className="form-control" disabled
          id="pennyReasonInput"
          value={data?.bankDetails?.pennyDropReason ?? ""}
        />
        {/* <div className="next-btn px-3">
          <button type="button" className="btn border border-2 border-dark px-3">
            Next
          </button>
        </div> */}
      </div>
    </div>
    </>
  )
}

export default BankDetails
