export const VERIFICATION_TABS = [
    {
      text: "Pan",
      id: 1,
    },
    {
      text: "Client Image",
      id: 2,
    },
    {
      text: "Signature",
      id: 3,
    },
    {
      text: "Address Proof (Permanent)",
      id: 4,
    },
    {
      text: "Bank Proof",
      id: 5,
    },
    {
      text: "Financial Proof",
      id: 6,
    },
    {
      text: "Nominee1",
      id: 7,
    },
    {
      text: "Nominee2",
      id: 8,
    },
    {
      text: "Nominee3",
      id: 9,
    },
  ];
  export const STEPPER_TABS = [
    {
      text: "Basic",
      id: 1,
    },
    {
      text: "Address",
      id: 2,
    },
    {
      text: "Bank",
      id: 3,
    },
    {
      text: "Other",
      id: 4,
    },
    {
      text: "Nominee",
      id: 5,
    },
  ];
  
  export const FILTER_DATA = [
    {
      id: "REQUESTED",
      text: "Requested",
    },
    {
      id: "REJECTED",
      text: "Rejected",
    },
    {
      id: "ESIGN_REQUESTED",
      text: "E-Sign Requested",
    },
    {
      id: "ESIGNED",
      text: "E-Sign Completed",
    },
    {
      id: "COMPLETED",
      text: "Completed",
    },
  ];
  