import React,{ useState } from 'react'
import Home from '../views/home';
import Logo from "../assest/nirmanLogo.png"
import Finovo from "../assest/Finovo/finovoLogo.png"
import ReKycHome from "../reKycAdmin/views/home"
import Closure from "../Closure/views/home"
function MainHome() {
    const [toggleState, setToggleState] = useState(1);
    const handleButtonClick = (index) => {
      setToggleState(index);
    };
  return (
    <>
    <div>
    <div className="flex bg-light p-2">
        <button onClick={() => handleButtonClick(1)} className="px-3 py-2 rounded border-light fw-bold bg-primary text-light mx-3">Kyc</button>
        <button onClick={() => handleButtonClick(2)} className="px-3 py-2 rounded border-light fw-bold bg-primary text-light mx-3">Re-Kyc</button>
        <button onClick={() => handleButtonClick(3)} className="px-3 py-2 rounded border-light fw-bold bg-primary text-light mx-3">Closure</button>
      </div>
      {toggleState === 1 ? <Home /> : null}
        {toggleState === 2 ? <ReKycHome /> : null} 
        {toggleState === 3 ? <Closure/> : null} 
        <div className="d-flex align-items-center justify-content-center m-auto">
              <p>All rights reserved ©2024 Powered by &nbsp;</p>{" "}
          <img src={Finovo} width="100px " className='mb-3 ' />
            </div>
    </div>
    </>
  )
}

export default MainHome