import React from "react";

const Loader = (props) => {
  return (
    <div>
      <div
        className={`backdrop ${props.open ? "visible" : "invisible"}`}
        style={{ zIndex: 1050, backgroundColor: "rgba(0,0,0,0.5)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%" }}
      >
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
