import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navbar } from '../../layout/navbar'
import ClientListing from '../clients'
import { Aadhar } from '../export/aadhar'
import Verification from '../../layout/verification'
import { ESigned } from "../export/esigned"
import { AuditTrail } from "../export/auditTrail"

function ReKycHome() {
  return (
    <div className='bg-secondary'>
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="*" element={ <ClientListing/> } />
        <Route path="/client/:clientId" element={<Verification />} />
        <Route path="/esigned/:clientId" element={<ESigned />} />
        <Route path="/aadhar/:clientId" element={<Aadhar />} />
        <Route path="/audit-trail/:clientId" element={<AuditTrail />} />
      </Routes>
      </BrowserRouter>

    </div>
  )
}

export default ReKycHome