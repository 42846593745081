import React, { useState, useEffect } from "react";
import "./styles.css";
import useApi from "../../hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/loader";
import Button from "react-bootstrap/Button";

const ClientImage = ({ data, updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const { loading, error, postData, fetchData } = useApi();
  const [imageData, setImageData] = useState("");
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);

  const epochTimestamp = +data?.imageAndSignature?.createdAt ?? 0;

  const dateObject = new Date(epochTimestamp);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  const formattedDate = epochTimestamp
    ? `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`
    : "";
  const formattedTime = epochTimestamp
    ? `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    : "";

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            clientImage: imageData,
          }
        );

        toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const handleConsole = (e) => {
    e.preventDefault();
    const newRem = remark === "" ? "" : `<b>CLIENT IMAGE</b>${remark}`;
    updateRejectData({ IMAGE: newRem });
    setRejectButtonDisabled(true);
    toast.success("Reject Added");
  };

  useEffect(() => {
    const storedRemark = localStorage.getItem("clientImage");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("clientImage", remark);
  }, [remark]);

  // const signatureValue = data?.rejectionList.find(item => item.startsWith("IMAGE:")) || "";
  const signatureValue = (data?.rejectionList || []).find(item => item.startsWith("IMAGE:")) || "";
  const inputValue = signatureValue.replace("IMAGE:", "") ? signatureValue.replace("IMAGE:", "") : remark;


  return (
    <div className="client-container border border-2">
      <ToastContainer />
      <Loader open={loading} />
      <div className="head-container">
        <div className="rejectInput-text d-flex m-3">
          <label for="floatingInputGroup1">
            <b
              className={`${
                Boolean(data?.rejectedDocumentDetails?.IMAGE)
                  ? "error-text"
                  : ""
              }`}
            >
              Reject Remark
            </b>
          </label>
          <input
            // disabled={Boolean(data?.rejectedDocumentDetails?.IMAGE)}
            value={inputValue}
            onChange={(e) => setRemark(e.target.value)}
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
          />
        </div>
        <div className="d-flex align-items-center">
          <Button onClick={handleConsole} disabled={inputValue == signatureValue.replace("IMAGE:", "")}>
            Reject
          </Button>{" "}
        </div>
      </div>
      <div className="document-area">
        <div className="clientImg-container">
          <img
            src={data?.rekycUserRequestedDetails?.image}
            alt=""
            width="70%"
          />
        </div>
        <div className="client-text ms-5 text-start">
          <b className="clientLocation-txt fs-3">Location</b>
          <hr className="line" />
          <p>
            <b>Longitude : </b>
            <input
              type="text"
              className="clientInput-text"
              value={data.rekycUserRequestedDetails?.latitude ?? "Not Available"}
            />
          </p>
          <p>
            <b>Latitude : </b>
            <input
              type="text"
              className="clientInput-text"
              value={data.rekycUserRequestedDetails?.longitude ?? "Not Available"}
            />
          </p>
          <p>
            <b>Address :</b>
            <input type="text" className="clientInput-text"
              style={{width:"500px"}}
            value={data?.rekycUserRequestedDetails?.currentGeoAddress}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export { ClientImage };
