import React from 'react';

const PdfPreview = ({ base64String }) => {

  const viewPdf = () => {
    const blob = new Blob([new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    return (
      <object data={url} type="application/pdf" width="100%" height="500px">
        <p>PDF cannot be displayed</p>
      </object>
    );
  };

  return (
    <div style={{ width: '100%', height: '500px' }}>
      {viewPdf()}
    </div>
  );
};

export default PdfPreview;
