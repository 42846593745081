import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import {
  ADMIN_BASE_URL,
  ADMIN_BACKOFFICE_BASE_URL,
  REDIRECT_URL,
} from "../../constant/constants";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import PdfPreview from "../../components/pdfViewer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CiSearch } from "react-icons/ci";
import { FILTER_DATA } from "../../constant/MapperConstant";

const ClientListing = () => {
  const navigation = useNavigate();
  const [load, setLoad] = React.useState(false);
  const [data, setData] = React.useState({ totalPage: 1 });
  const [showAadhar, setShowAadhar] = React.useState(false);
  const [showAudit, setShowAudit] = useState(false);
  const [show, setShow] = React.useState(false);
  const [fullscreen, setFullscreen] = React.useState(true);
  const [loader, setLoader] = useState(false);
  const [filteredData, setFilteredData] = React.useState([0]);
  const [aadharXml, setAadharXml] = React.useState("");
  const [searchData, setSearchData] = React.useState({
    search: "",
    page: 1,
    filter: "REQUESTED",
  });
  const handleClose = () => setShowAadhar(false);
  const { loading, fetchData } = useApi();
  const [base64String, setBase64String] = React.useState("");
  async function getClientList() {
    try {
      const data = await fetchData(
        `rekyc/users/details?search=${searchData.search}&page=${searchData.page}&rekycstatus=${searchData.filter}`,
        {},
        ADMIN_BASE_URL
      );
      setData(data.pageDetail);
      // console.log(data.rekycUserBasicAdminContent)
      setFilteredData(data?.rekycUserBasicAdminContent ?? []);
    } catch (e) {
      toast.error("Error while fetching data");
    }
  }

  React.useEffect(() => {
    getClientList();
  }, [searchData.page, searchData.search, searchData.filter]);

  const getDataTime = (epoch) => {
    const date = new Date(+epoch);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  const handleApiCalls = async (url, { }, BASE_URL, successMsg, errorMsg) => {
    const baseUrl = ADMIN_BACKOFFICE_BASE_URL ?? BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      toast.success(successMsg);
      return data;
    } catch (e) {
      toast.error(errorMsg);
    }
  };
  const handleSaveClick = () => {
    // Create a Blob with the state data
    const blob = new Blob([aadharXml], { type: "text/plain" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "aadharXml.xml";

    // Append the link to the body and click it
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);

    console.log("State saved successfully!");
  };
  const handleRedirectToDetails = (clientId, userid) => {
    navigation(`client/${userid}`, {
      state: { userid: userid },
    });
    console.log("==>", userid);
  };

  // const handleAudit = (row) => {
  //   setShowAadhar(false)
  //   const authToken = sessionStorage.getItem("authToken");
  //   const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/export/button/${row.phoneNumber}`;
  //   console.log("======>", authToken);
  //   console.log("======>", apiUrl);

  //   fetch(apiUrl, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': authToken,
  //       'Content-Type': 'application/json', // Add any other required headers
  //     },
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       // Handle the API response data
  //       console.log('API Response:', data);
  //       console.log('API Response:', data.aadharXml);
  //       setAadharXml(data.aadharXml)
  //       setShowAudit(true)
  //       // Perform any additional actions with the data as needed
  //     })
  //     .catch(error => {
  //       // Handle errors
  //       console.error('Error:', error);
  //       setShowAudit(true)
  //     });
  // }

  // const handleGeneratePdf = async (mobileNo, baseUrl) => {
  //   try {
  //     const pdfData = await handleApiCalls(
  //       `ekyc/user/pdf/${mobileNo}`,
  //       baseUrl
  //     );
  //     setBase64String(pdfData?.message ?? "");
  //     toast.success("PDF generated successfully");
  //   } catch (error) {
  //     toast.error("Failed to generate PDF");
  //   }
  // };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const handleExportData = async (
    url,
    BASE_URL,
    successMsg,
    errorMsg,
    mobileNo
  ) => {
    const data = await handleApiCalls(url, BASE_URL, successMsg, errorMsg);
    openInNewTab(`${REDIRECT_URL}aadhar/${mobileNo}`);
    openInNewTab(`${REDIRECT_URL}esigned/${mobileNo}`);
    openInNewTab(`${REDIRECT_URL}audit-trail/${mobileNo}`);
  };

  const handleFilter = (value) => {
    if (value === "ALL") {
      setSearchData((prev) => ({ ...prev, page: 1, filter: "" }));
    } else {
      setSearchData((prev) => ({ ...prev, page: 1, filter: value }));
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value?.trim() ?? "";
    if (
      (e.key === "Enter" && searchValue) ||
      (!searchValue && searchData.search)
    ) {
      setSearchData((prev) => ({ ...prev, search: searchValue, page: 1 }));
    }
    e.preventDefault();
  };
  const handleBse = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/push/bse";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(`BSE ${responseData.status}`);
      } else {
        toast.error(`BSE ${responseData.message}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // No need to parse response.json() again here
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // You can add a toast message for the error if needed
      // toast.error('Error fetching data:', error.message)
    } finally {
      setLoader(false);
    }
  };

  const handleNse = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/push/nse";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`NSE ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };
  const handleBackOffice = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/push/backoffice";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`BackOffice ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };
  const handleCdsl = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/push/cdsl";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(`CDSL ${responseData.status}`);
      } else {
        toast.error(`CDSL ${responseData.message}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // No need to parse response.json() again here
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // You can add a toast message for the error if needed
      // toast.error('Error fetching data:', error.message)
    } finally {
      setLoader(false);
    }
  };
  const CompleteButton = (user_id) => {
    const authToken = sessionStorage.getItem("authToken");
    const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/stage/change/complete`;
    setLoader(true)
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
        xuserid: user_id,
      },
    })
      .then((response) => {
        setLoader(false)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const ExportButton = (userid_id) => {
    const authToken = sessionStorage.getItem("authToken");
    const apiUrl = `https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/esigned/export/docs`;
    setLoader(true)
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
        xuserid: userid_id,
      },
    })
      .then((response) => {
        setLoader(false);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // Save the response for later use
        return Promise.all([response.blob(), response.headers]);
      })
      .then(([blob, headers]) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        // Assuming the server sets appropriate content-disposition headers
        const contentDisposition = headers.get("content-disposition");
        let fileName = "downloaded_file.pdf"; // Default file name

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        }

        downloadLink.download = fileName;

        // Append the link to the body and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
      });
  };

  const handleKRA = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://vramathkyc.finovo.tech:4590/app2/api/v1/rekyc/push/kra/upload";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`KRA ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };
  //   toast.success("KRA Successful");
  // };

  // const handleAudit = () => {
  //   if (!auditTrailPdf) {
  //     console.error('auditTrailPdf is not defined!');
  //     return;
  //   }

  //   // Convert the base64 PDF data to a Uint8Array
  //   const pdfData = atob(auditTrailPdf);
  //   const dataArray = new Uint8Array(pdfData.length);
  //   for (let i = 0; i < pdfData.length; i++) {
  //     dataArray[i] = pdfData.charCodeAt(i);
  //   }

  //   // Create a Blob with the PDF data
  //   const blob = new Blob([dataArray], { type: 'application/pdf' });

  //   // Create a download link
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = window.URL.createObjectURL(blob);
  //   downloadLink.download = 'auditTrailPdf.pdf';

  //   // Append the link to the body and click it
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();

  //   // Remove the link from the DOM
  //   document.body.removeChild(downloadLink);

  //   console.log('PDF downloaded successfully!');
  // };

  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={fullscreen}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i>Aadhar Xml</i> <hr />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{aadharXml}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <Button>Export Audit</Button>
          <Button variant="primary" onClick={handleSaveClick}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Loader open={loading} />
      <Loader open={loader} />
      {base64String ? (
        <Modal
          size="lg"
          dialogClassName="modal-90w modal-90h"
          show={Boolean(base64String)}
          onHide={() => setBase64String("")}
        >
          <PdfPreview base64String={base64String} />
        </Modal>
      ) : (
        <></>
      )}
      <ToastContainer />
      <Container fluid>
        <Row>
          <div className="d-flex justify-content-center gap-5 align-items-center">
            <div className="w-25 d-flex">
              <input
                onKeyUpCapture={handleSearch}
                className="w-100 py-2 rounded-pill rounded-end-0 border border-end-0"
                active="border-0"
                placeholder="Enter Mobile Number"
              />
              <div className="bg-white pt-1  rounded-pill rounded-start-0">
                <CiSearch className="fs-3 me-3" />
              </div>
            </div>
            <DropdownButton
              variant={searchData.filter ? "success" : "info"}
              className="my-3"
              id="dropdown-basic-button"
              title={searchData?.filter ? searchData?.filter : "Select Filter"}
            >
              {FILTER_DATA.map((item, idx) => (
                <Dropdown.Item
                  key={item.id}
                  onClick={() => handleFilter(item.id)}
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>

          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr className="text-center bg-white">
                  <th>Action</th>
                  <th>PAN</th>
                  <th>RE KYC Status</th>
                  <th>Client Code</th>
                  <th>Customer Name</th>
                  <th>Mob No.</th>
                  {/* <th>Payment Status</th> */}
                  <th>Email Change</th>
                  <th>Mobile Change</th>
                  <th>Address Change</th>
                  <th>Bank Change</th>
                  <th>Date</th>
                  <th>Freeze/Unfreeze</th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete BackOffice
                  </th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete NSE
                  </th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete BSE
                  </th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete KRA
                  </th>
                  <th
                    className={`p-3 ${searchData.filter === "REQUESTED" ||
                      searchData.filter === "REJECTED" ||
                      searchData.filter === "ESIGNED" ||
                      searchData.filter === "ESIGN_REQUESTED"
                      ? "d-block"
                      : ""
                      }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                  >
                    Complete
                  </th>
                  <th></th>
                  <th
                    className={`p-3 ${searchData.filter === "REQUESTED" ||
                      searchData.filter === "REJECTED" ||
                      searchData.filter === "ESIGNED" ||
                      searchData.filter === "ESIGN_REQUESTED"
                      ? "d-block"
                      : ""
                      }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                  >
                    Export
                  </th>
                </tr>
              </thead>
              {filteredData && filteredData.length ? (
                <tbody align="center">
                  {filteredData.map((row) => (
                    <tr key={row.mobileNo}>
                      <td>
                        <Button
                          onClick={() =>
                            handleRedirectToDetails(row.clientCode, row.userid)
                          }
                          variant="primary"
                        >
                          {"Verify"}
                        </Button>
                      </td>{" "}
                      <td>
                        {row?.rekycUserBasicAdminContent?.panNumber ?? "N/A"}
                      </td>

                      <td>{row?.rekycStatus}</td>
                      <td>{row?.clientCode ?? "N/A"}</td>
                      <td>{row?.name}</td>
                      <td>{row?.phoneNumber}</td>
                      <td>{row?.emailChanged ? "Yes" : "No"}</td>
                      <td>{row?.mobileChanged ? "Yes" : "No"}</td>
                      <td>{row?.addressChanged ? "Yes" : "No"}</td>
                      <td>{row?.bankChanged ? "Yes" : "No"}</td>
                      <td>{getDataTime(row?.updateDate)}</td>
                      <td>freeze</td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row.kycStatus === "false"}
                          onClick={() => {
                            handleBackOffice(row.userid);
                          }}
                          variant="success"
                        >
                          Complete BackOffice
                        </Button>
                      </td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row.kycStatus === "false"}
                          onClick={() => {
                            handleNse(row.userid);
                          }}
                          variant="success"
                        >
                          Complete NSE
                        </Button>
                      </td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row.rekycStatus === "false"}
                          onClick={() => {
                            handleBse(row.userid);
                          }}
                          variant="success"
                        >
                          Complete BSE
                        </Button>
                        {load && <Loader open={load} />}
                      </td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row.rekycStatus === "false"}
                          onClick={() => { handleKRA(row.userid_id) }}
                          variant="success"
                        >
                          Complete KRA
                        </Button>
                        {load && <Loader open={load} />}
                      </td>
                      <td
                        className={`p-3 ${searchData.filter === "REQUESTED" ||
                          searchData.filter === "REJECTED" ||
                          searchData.filter === "ESIGNED" ||
                          searchData.filter === "ESIGN_REQUESTED"
                          ? "d-block"
                          : ""
                          }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                      >
                        <Button
                          className="p-3"
                          disabled={row.clientCode === "NULL"}
                          onClick={() => CompleteButton(row.userid)}
                          variant="success"
                        >
                          Complete
                        </Button>
                      </td>
                      <td></td>
                      <td
                        className={`p-3 ${searchData.filter === "REQUESTED" ||
                          searchData.filter === "REJECTED" ||
                          searchData.filter === "ESIGNED" ||
                          searchData.filter === "ESIGN_REQUESTED"
                          ? "d-block"
                          : ""
                          }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                      >
                        <Button
                          disabled={row.rekycStatus === "true"}
                          onClick={() => ExportButton(row.userid)}
                          variant="success"
                        >
                          Export
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <p className="no-data-available">No Data Available</p>
              )}
            </Table>
          </Col>
        </Row>
        <Pagination className="px-4">
          {Array(+data?.totalPage ?? 1)
            ?.fill(1)
            ?.map((_, index) => {
              return (
                <Pagination.Item
                  onClick={() =>
                    setSearchData((prev) => ({ ...prev, page: index + 1 }))
                  }
                  key={index + 1}
                  active={index + 1 === searchData.page}
                >
                  {index + 1}
                </Pagination.Item>
              );
            })}
        </Pagination>
      </Container>
    </div>
  );
};

export default ClientListing;
