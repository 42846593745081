import React, { useState } from "react";
import { VERIFICATION_TABS } from "../../constant/MapperConstant";
import { PanInfo } from "../panInfo";
import { ClientImage } from "../clientImage";
import ClientSignature from "../signatureImage";
import AddressProof from "../addressProof";
import BankProof from "../bankProof";
import FinancialProof from "../financialProof";
import Nominee1 from "../nominee1";
import Nominee2 from "../nominee2";
import Nominee3 from "../nominee3";
import "./styles.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
const Slider = ({ clientData }) => {
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  const [activeTab, setActiveTab] = useState(VERIFICATION_TABS[0]);

  const [rejectData, setRejectData] = useState({
    PAN_CARD: "",
    IMAGE: "",
    SIGNATURE: "",
    BANK_PROOF: "",
    FINANCIAL_PROOF: "",
    NOMINEE_ONE: "",
    NOMINEE_TWO: "",
    NOMINEE_THREE: "",
  });

  const handleOnClick = (item) => {
    setActiveTab(item);
  };

  const handleFinalSubmit = async () => {
    const token = sessionStorage.getItem("authToken");
    // console.log("Reject Remarks:", rejectData);
    const user = clientData.backOfficeDetails.userId;
    console.log("------------------", user);
    try {
      setLoader(true);
      const res = await fetch(
        `https://vramathkyc.finovo.tech:4590/app2/api/v1/reject/rekyc/send/rejection`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            xuserid: user,
            Authorization: token,
          },
          body: JSON.stringify(rejectData),
        }
      );

      if (res.ok) {
        console.log("Submission successful");
      } else {
        console.error("Submission failed");
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoader(false);
    }
    localStorage.clear();
  };
  const updateRejectData = (newRejectData) => {
    setRejectData((prevRejectData) => ({
      ...prevRejectData,
      ...newRejectData,
    }));
    console.log("--------------", newRejectData);
  };

  const renderActiveComponent = () => {
    switch (activeTab.id) {
      case 1:
        return (
          <PanInfo data={clientData} updateRejectData={updateRejectData} />
        );
      case 2:
        return (
          <ClientImage data={clientData} updateRejectData={updateRejectData} />
        );
      case 3:
        return (
          <ClientSignature
            data={clientData}
            updateRejectData={updateRejectData}
          />
        );
      case 4:
        return (
          <AddressProof data={clientData} updateRejectData={updateRejectData} />
        );
      case 5:
        return (
          <BankProof data={clientData} updateRejectData={updateRejectData} />
        );
      case 6:
        return (
          <FinancialProof
            data={clientData}
            updateRejectData={updateRejectData}
          />
        );
      case 7:
        return (
          <Nominee1 data={clientData} updateRejectData={updateRejectData} />
        );
      case 8:
        return (
          <Nominee2 data={clientData} updateRejectData={updateRejectData} />
        );
      case 9:
        return (
          <Nominee3 data={clientData} updateRejectData={updateRejectData} />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div>
        <Loader open={loader} />
        <div className="m-2 d-flex justify-content-end">
          <button
            onClick={() => navigation("/")}
            type="button"
            className="btn btn-primary bg-primary"
          >
            back
          </button>
        </div>
        <div className="d-flex justify-content-between bg-info p-3 rounded-top-3 overflow-x-auto w-100">
          {VERIFICATION_TABS.map((item) => (
            <li
              className={`slide-tabs font-bold ${
                item.id === activeTab.id
                  ? "active-tab text-white bg-primary rounded-pill text-center"
                  : "text-teal-400"
              }`}
              onClick={() => handleOnClick(item)}
              key={item.id}
            >
              {item.text}
            </li>
          ))}
        </div>
        {activeTab && renderActiveComponent()}
      </div>
      <div className="">
        <Button
          onClick={handleFinalSubmit}
          variant="success"
          className={
            clientData &&
            (clientData.currentStage === "ESIGN_REQUESTED" ||
              clientData.currentStage === "ESIGNED")
              ? "d-none"
              : ""
          }
        >
          Rejected Request Send
        </Button>
      </div>
    </>
  );
};

export default Slider;
